import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/passport',
    name: 'passport',
    component: () => import(/* webpackChunkName: "about" */ '../views/passport-view.vue'),
  },
  {
    path: '/evaluate',
    name: 'evaluate',
    component: () => import(/* webpackChunkName: "about" */ '../views/evaluate-view.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
