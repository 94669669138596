<template>
  <div class="one-level-title">
    <img class="slash" src="@/assets/img/slash.svg" />
    <img class="lang-horizontal-line" src="@/assets/img/lang-horizontal-line.svg" />
    <img class="short-horizontal-line" src="@/assets/img/short-horizontal-line.svg" />
    <div class="title">{{ title }}</div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: '大标题'
    }
  }
}
</script>
<style lang="scss" scoped>
@keyframes breathe {
  0% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 0;
  }
}
@keyframes swing {
  0% {
    right: 15px;
  }
  50% {
    right: 100px;
  }
  100% {
    right: 15px;
  }
}
.one-level-title {
  position: relative;
  background: url('~@/assets/img/one-level-title-bg.png') no-repeat;
  height: 80px;
  background-size: 100% 100%;
  img {
    width: 100%;
  }
  .title {
    position: absolute;
    top: 3px;
    left: 100px;
    font-size: 2.8rem;
    font-weight: bolder;
    font-style: italic;
    letter-spacing: 5px;
  }
  .slash {
    width: auto;
    height: 40px;
    position: absolute;
    top: 0;
    left: 0;
  }
  .lang-horizontal-line {
    width: auto;
    height: 4px;
    position: absolute;
    top: 73px;
    left: 6%;
    animation: breathe 1s linear 1s infinite alternate;
  }
  .short-horizontal-line {
    width: auto;
    height: 5px;
    position: absolute;
    top: 65px;
    right: 15px;
    animation: swing 5s linear infinite;
  }
}
</style>
