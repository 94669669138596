<template>
  <div id="app">
    <fa-full-screen-container>
      <screen-header />
      <router-view class="content flex-box"/>
    </fa-full-screen-container>
  </div>
</template>
<script>
import ScreenHeader from '@/views/screen-header.vue';

export default {
  components: {
    ScreenHeader,
  }
};
</script>
<style scoped lang="scss">
#app {
  width: 100%;
  height: 100%;
}
.content {
  height: calc(100% - 160px);
  padding: 80px;
  box-sizing: border-box;
}
</style>
