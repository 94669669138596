import Vue from 'vue';
import dataV from '@jiaminghi/data-view';
import '@/assets/scss/index.scss';
import router from './router';
import store from './store';
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
Vue.use(dataV);
import App from './App.vue';

import fullScreenContainer from '@/components/common/fullScreenContainer/index'
Vue.use(fullScreenContainer)

// 一级标题
import OneLevelTitle from '@/components/common/one-level-title.vue';
Vue.component('OneLevelTitle', OneLevelTitle)
// 数字翻牌器
import countTo from 'vue-count-to';
Vue.component('CountTo', countTo)
// 轮播
import VueSuperSlide from 'vue-superslide'
Vue.use(VueSuperSlide)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
