<template>
  <div class="flex-box header-wrap">
    <div class="header-bg-1"></div>
    <div class="header-bg-2"></div>
    <div class="header-menu">
      <div class="menu-button">事项数据</div>
      <div class="menu-button">办件数据</div>
    </div>
    <div class="header-title">优化政务服务 提升行政效能 “高效办成一件事”</div>
    <div class="header-menu">
      <div class="menu-button" @click="$router.push({name: 'passport'})">电子证照数据</div>
      <div class="menu-button" @click="$router.push({name: 'evaluate'})">“好差评”数据</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ScreenHeader',
};
</script>
<style scoped lang="scss">
.header-wrap {
  height: 150px;
  position: relative;
  .header-bg-1 {
    position: absolute;
    z-index: -1;
    bottom: 0;
    width: 100%;
    height: 220px;
    background: url('@/assets/img/header-bg-1.png') no-repeat;
    background-size: contain;
    background-position: center;
  }
  .header-bg-2 {
    position: absolute;
    z-index: -1;
    bottom: -30px;
    width: 100%;
    height: 100px;
    background: url('@/assets/img/header-bg-2.png') no-repeat;
    background-size: contain;
    background-position: bottom;
  }
  .header-menu {
    flex: 0 1 25%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: end;
    margin-top: 40px;
    .menu-button {
      cursor: pointer;
      font-size: 2.5rem;
      border: 5px solid #008DFF;
      background-color: rgba(0, 144, 255, .5);
      padding: 5px 20px;
      border-radius: 30px;
      min-width: 250px;
      text-align: center;
    }
  }
  .header-title {
    flex: 0 1 50%;
    height: 100%;
    padding-left: 20px;
    line-height: 2em;
    text-align: center;
    font-size: 5rem;
    font-family: serif, 'fangsong';
    font-weight: bolder;
    color: rgb(255, 209, 98);
    z-index: 9999;
  }
}
</style>
